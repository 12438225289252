import Vue from 'vue'
import axios from "@axios";

export default class UserSettings extends Vue {

    loadSettings(id){
            axios
                .post("core/user-settings/load", {
                    id_user: id
                })
                .then((res) => {           
                    localStorage.setItem('userSettings', JSON.stringify(res.data));
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                });
    }

    async saveSettings(settings, type){
        var userData = JSON.parse(localStorage.getItem('user-portal'));
            await axios
                .post("core/user-settings/save", {
                    id_user: userData.id,
                    settings:JSON.stringify(settings),
                    type: type
                })
                .then((res) => {               
                    localStorage.setItem('userSettings', JSON.stringify(res.data));                   
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                });
    }

    async checkSettings(id_settings_const_type, filters, list_type){
        var getData = JSON.parse(localStorage.getItem('userSettings'));
       
        let unsaved_filters = ['changed', 'page', 'search', 'group_by', 'date_type', 'sortBy', 'sortDesc', 'ids_shift_data_main', 'ids_group_const_type'];

        if(id_settings_const_type == 12){
            unsaved_filters.push('filtering')
        }

        if(getData == undefined || getData[list_type] == undefined){
            await this.saveSettings(filters, id_settings_const_type);
            getData = JSON.parse(localStorage.getItem('userSettings'));
        }else{

            for(var key in filters){
                if(!unsaved_filters.includes(key) && !getData[list_type].hasOwnProperty(key)){
                    await this.saveSettings(filters, id_settings_const_type);
                    getData = JSON.parse(localStorage.getItem('userSettings'));
                    break;
                }
            }

            if(id_settings_const_type != 12){
                for(var key in filters.filtering){
                    if(!getData[list_type].filtering.hasOwnProperty(key)){
                        await this.saveSettings(filters, id_settings_const_type);
                        getData = JSON.parse(localStorage.getItem('userSettings'));
                        break;
                    }
                }
            }

        }

        return getData[list_type];
    }
}


