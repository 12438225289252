<template>
    <div class="mt-3 pt-3" v-if="userRole == 5 || (userRole == 6 && userPlacement == 1)">
        <b-card class>
            <b-overlay :show="loading_list" :opacity="0.0">

                <app-view-table :title="trans('cp-documents',286)" :isTitle="true" :loading="loading_list" :isTitleTotal="true" :filters.sync="filters" :items="items" :fields="fields" :isDropdownMenu="true" resizeableColumns resizeMinWidth v-on:onRowClicked="item=>openDocument(item)" :rowPointerCursor="true">
                    <template v-slot:menu-items="cell">
                            <b-dropdown-item @click="openDocument(cell.data.item)">{{trans('cp-show-document',286)}}</b-dropdown-item>
                    </template>
                </app-view-table>
                
                <template #overlay>
                    <div></div>
                </template>
            </b-overlay>
        </b-card> 
    </div>
</template>
<script>

import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import UserSettings from '@core/scripts/UserSettings';

export default {

    created(){
        this.userRole = JSON.parse(localStorage.getItem('user-portal')).id_user_const_role
        this.userPlacement = JSON.parse(localStorage.getItem('user-portal')).placement
    },

    mounted() {
        this.onCreateThis();
    },

    data() {
        return {
            alert_class: new AppAlerts(),
            loading_list: false,
            userSettings: new UserSettings(),
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {   
                    document_types: {type: 'list', changed: 0, values: [], function: "getDocumentTypesPortal", parameters: null, widthClass:'app-width-min-250'},                      
                    dates: { type: 'dates', changed: 0, values: [],  function: "", parameters: {type: null}, widthClass:'app-width-min-350'},  
                    persons: {type: 'list', changed: 0, values: [], function: "getDocumentPersonsPortal", parameters: null, widthClass:'app-width-min-250'},                                                        
                },
                page: 1,
                per_page: 20,
                changed: 0,
                search: ""
            },

            fields: [
                { key: "document_type_name", label: this.trans('cp-document-type', 286), thStyle: { "min-width": "400px", "max-width": "450px" }, visible: true, filtering: true, filtering_name: "document_types"},
                { key: "entry_date", label: this.trans('pl-date', 288), thStyle: { "min-width": "140px", "max-width": "140px" }, visible: true, filtering: true, filtering_name: "dates"},
                { key: "document_name", label: this.trans('sh-name', 292), thStyle: { "min-width": "110px", width: "100%" }, visible: true, filtering: false}, 
                { key: "placement_person_name", label: this.trans('doc-person-name', 290), thStyle: { "min-width": "300px", "max-width": "300px" }, visible: true, filtering: true, filtering_name: "persons"},    
            ],

            items: []
        };
    },

    methods: {
        getList(){

            this.loading_list = true;

            axios
                .post('clients/documents/getList', {
                    filters: JSON.stringify(this.filters)
                })
                .then((res) => {
                    this.items = res.data.items;
                })
                .catch((error) => { 
                    console.error(`error: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                })

        },

        openDocument(data){
            this.loading_list = true;
            axios
                .post("clients/documents/downloadFile", {
                    id_document_data_main: data.id
                }, 
                {
                    responseType: 'blob', 
                    crossDomain: true,
                })
                .then((res) => {

                    const url = window.URL.createObjectURL(new Blob([res.data], {type : data.mine}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.target= "_blank";
                     data.mine == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                     data.mine == 'application/msword' ||
                     data.mine == 'text/plain'
                                     ? link.setAttribute('download', data.document_name):link.setAttribute('open', data.document_name); 
                    link.setAttribute('open', data.document_name); 
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    //console.error(error);
                    this.alert_class.openAlertWarning(this.trans('file-not-found',281)).then(res => {})
                })
                .finally(() => {
                   this.loading_list = false;
                });
        },

        saveSettings() {
            this.userSettings.saveSettings(this.filters, 26);
        },

        async loadSettings() {

            var sFilters = await this.userSettings.checkSettings(26, this.filters, 'portalDocumentsList');

            this.filters.filtering = sFilters.filtering;
            this.filters.sorting = sFilters.sorting;
            this.filters.per_page = sFilters.per_page != undefined ? sFilters.per_page : 10;  
            this.filters.page = sFilters.page != undefined ? sFilters.page : 1;    
            
            this.getList();
        },

        onCreateThis(){

            this.loadSettings();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading && !this.loading_component) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading && !this.loading_component) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (!this.loading && !this.loading_component) {
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading && !this.loading_component) {
                    this.getList();
                    this.saveSettings();
                }   
            });

            this.$watch('filters.filtering.document_types.changed', function(newVal, oldVal) {
                if (!this.loading && !this.loading_component) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.dates.changed', function(newVal, oldVal) {
                if (!this.loading && !this.loading_component) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.persons.changed', function(newVal, oldVal) {
                if (!this.loading && !this.loading_component) {
                    this.getList();
                    this.saveSettings();
                } 
            });
        }

    },
 
};
</script>